// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login1',
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login1',
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/',
        keepAlive: false,
    },
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login1',
        keepAlive: false,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/event-promotion',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/activity-management/event-promotion/index.jsx'),
    },
    {
        path: '/fixed-activities',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/activity-management/fixed-activities/index.jsx'),
    },
    {
        path: '/periodic-activities',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/activity-management/periodic-activities/index.jsx'),
    },
    {
        path: '/address-library',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/address-library/index.jsx'),
    },
    {
        path: '/banners',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/banners/index.jsx'),
    },
    {
        path: '/category-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/category-management/index.jsx'),
    },
    {
        path: '/dictionary-table',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/dictionary-table/index.jsx'),
    },
    {
        path: '/example/ajax',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/examples/ajax/index.jsx'),
    },
    {
        path: '/example/customer-header',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/examples/customer-header/index.jsx'),
    },
    {
        path: '/forum-manager',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/forum-manager/index.jsx'),
    },
    {
        path: '/freight-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/freight-management/index.jsx'),
    },
    {
        path: '/good-homep-setting',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/good-homep-setting/index.jsx'),
    },
    {
        path: '/goods-management/_/goods_edit/:id/:pid',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/goods-management/details.jsx'),
    },
    {
        path: '/goods-management',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/goods-management/index.jsx'),
    },
    {
        path: '/',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/iframe/index.jsx'),
    },
    {
        path: '/labels',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/labels/index.jsx'),
    },
    {
        path: '/login1',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/login/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/login1/index.jsx'),
    },
    {
        path: '/material-library',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/material-library/index.jsx'),
    },
    {
        path: '/sold-items',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/order-management/sold-items/index.jsx'),
    },
    {
        path: '/settings',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/setting/index.jsx'),
    },
    {
        path: '/user_orders/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/user-orders/Edit.jsx'),
    },
    {
        path: '/user-orders',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/user-orders/index.jsx'),
    },
    {
        path: '/users/_/edit/:id',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/users/Edit.jsx'),
    },
    {
        path: '/users',
        component: () => import('E:/uni星球/unifamily-new/unifamily/mallmanager/src/pages/users/index.jsx'),
    },
];
