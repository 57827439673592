import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Upload, Icon, message } from "antd";
import { FormElement } from "@/library/components";
import OSS from "ali-oss";
import moment from "moment";
import "./style.less";
import _ from "lodash";

/**
 * 可标记表格高阶组件，dataSource中每条数据，必须含有id作为唯一标识
 * columns 添加 formProps
 * */
export default function Editable(OriTable) {
    @Form.create()
    class EditableCell extends React.Component {
        // 使重置起作用
        componentDidUpdate(prevProps, prevState, snapshot) {
            const { form, record, dataIndex } = this.props;
            const prevRecord = prevProps.record;
            const prevValue = prevRecord && prevRecord[dataIndex];
            const value = record && record[dataIndex];

            if (value !== prevValue) form.resetFields();
        }

        // 截流触发，提高性能
        handleChange = _.debounce(() => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (err) return;

                const { value, dataSource, onChange } = this.props;
                if (onChange) {
                    const source = value || dataSource;

                    const ds = source.map((item) => {
                        const { id } = item;
                        return { ...item, ...values[id] };
                    });
                    onChange(ds);
                }
            });
        }, 500);
        beforeUpload(file) {
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                message.error("只允许上传JPG/PNG图片!");
            }
            return isJpgOrPng;
        }
        handleUploadChange = (file, fileList, e, index,dataIndex) => {
            this.toOSSFun(file.file.originFileObj, index,dataIndex);
        };
        guid() {
            var guid = "";
            for (var i = 1; i <= 32; i++) {
                var n = Math.floor(Math.random() * 16.0).toString(16);
                guid += n;
                if (i === 8 || i === 12 || i === 16 || i === 20) guid += "-";
            }
            return guid;
        }
        async toOSSFun(file, index,dataIndex) {
            // media={{uploadFn: this.myUploadFn}}
            let client = new OSS({
                accessKeyId: "LTAI5tNXP5GyAaynNsdqGPcg", // 后台的临时签名ID
                accessKeySecret: "EybNKIvJo3YN01x8gfp352t3q9xYcQ", // 后台的临时签名密钥
                endpoint: "oss-cn-shanghai.aliyuncs.com", // 上传后的域名
                bucket: "unifamily", // OSS仓库名
            });
            let curTime = new Date();
            let year = curTime.getFullYear(),
                month = curTime.getMonth() + 1,
                day = curTime.getDate();
            let dir =
                "mall/product/" +
                index +
                "/" +
                year +
                "/" +
                (month < 10 ? "0" + month : month) +
                "-" +
                (day < 10 ? "0" + day : day) +
                "/";
            let result = await client.put(dir + this.guid() + ".png", file);
            let url = result.url;
            let path = result.name
            this.props.form.setFieldsValue({[`${index}[${dataIndex}]`]:path})
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (err) return;

                const { value, dataSource, onChange } = this.props;
                if (onChange) {
                    const source = value || dataSource;

                    const ds = source.map((item,idy) => {
                        if(idy===index){
                            item[`${dataIndex}`]=path
                            item['sku_url']=url
                        }
                        return { ...item};
                    });
                    onChange(ds);
                }
            });
               
        }
        renderCell = () => {
            const uploadButton = (
                <div>
                    <Icon type="plus" />
                    <div className="ant-upload-text">上传图片</div>
                </div>
            );
            const {
                form,
                record,
                title,
                dataIndex,
                formProps,
                type,
                size,
                suffix,
                required,
                index
            } = this.props;
            console.log(index)
            const { id } = record;
            const field = `${id}[${dataIndex}]`;
            const value = record[dataIndex];
            if (type === "date-time") {
                return (
                    <FormElement
                        {...formProps}
                        type={type}
                        form={form}
                        label={title}
                        labelWidth={formProps.required ? 10 : 0}
                        colon={false}
                        field={field}
                        initialValue={
                            value ? moment(value, "YYYY-MM-DD HH:mm:ss") : null
                        }
                        onChange={this.handleChange}
                        size={size}
                    />
                );
            } else if (type === "upload") {
                return (
                    <FormElement
                        {...formProps}
                        label={title}
                        field={`${index}[${dataIndex}]`}
                        required={required}
                        initialValue={value}
                        placeholder="请上传规格照片"
                    >
                        <Upload
                            name="avatar"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            beforeUpload={this.beforeUpload}
                            onChange={(file, fileList, event) =>
                                this.handleUploadChange(
                                    file,
                                    fileList,
                                    event,
                                    index,
                                    dataIndex
                                )
                            }
                            fileList={[]}
                        >
                            {record.sku_url ? (
                                <img
                                    src={record.sku_url}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                />
                            ) : (
                                uploadButton
                            )}
                        </Upload>
                    </FormElement>
                );
            } else {
                return (
                    <FormElement
                        {...formProps}
                        type={type || "input"}
                        form={form}
                        label={title}
                        labelWidth={formProps.required ? 10 : 0}
                        colon={false}
                        field={field}
                        initialValue={value}
                        onChange={this.handleChange}
                        size={size}
                        suffix={suffix}
                        required={required}
                    />
                );
            }
        };

        render() {
            const {
                editable,
                title,
                dataIndex,
                record,
                value,
                onChange,
                formProps,
                form,
                children,
                ...restProps
            } = this.props;
            return (
                <td {...restProps}>
                    {editable ? this.renderCell() : children}
                </td>
            );
        }
    }

    class EditableTable extends Component {
        static propTypes = {
            dataSource: PropTypes.array,
            columns: PropTypes.array,
            value: PropTypes.array,
            onChange: PropTypes.func,
        };

        render() {
            const {
                value,
                dataSource,
                onChange,
                columns,
                rowKey,
                size,
                ...others
            } = this.props;

            const ds = value || dataSource;

            if (!this.initDataSource && ds?.length) this.initDataSource = ds;

            const components = {
                body: {
                    cell: EditableCell,
                },
            };

            const nextColumns = columns.map((col) => {
                const editable = !!col.formProps;
                if (!editable) return col;
                return {
                    ...col,
                    onCell: (record,index) => ({
                        record,
                        value: value || dataSource,
                        formProps: col.formProps,
                        editable,
                        title: col.title,
                        dataIndex: col.dataIndex,
                        onChange,
                        size,
                        type: col.type,
                        suffix: col.suffix,
                        required: col.required,
                        index,
                    }),
                };
            });
            return (
                <OriTable
                    className="table-editable-root"
                    {...others}
                    size={size}
                    rowKey={rowKey}
                    components={components}
                    columns={nextColumns}
                    dataSource={ds}
                />
            );
        }
    }

    return EditableTable;
}
